import React, { Component } from "react";
import "./startContent.css";

export default class StartContent extends Component {
  render() {
    const { handleStartButton } = this.props;

    return (
      <div className="backgroundStart">
        <div className="introText">
          <text className="introText1">
            {
              "Choosing the right pillow can improve the quality of your sleep, and make other activities, such as watching TV or reading a lot more comfortable. But what is right?"
            }
          </text>
          <br />
          <br />
          <text className="introText2">
            {
              "To find the One, you should consider your most frequent sleeping position, allergies, health issues and after you have your needs covered, you can move on to your wants: softness, cleanability, fullness, sustainability. This may seem like a daunting task, but we are here to help."
            }
          </text>
          <br />
          <br />
          <text className="introText3">
            {
              "Take our quiz, and we'll show you the pillow built exactly for your dreams."
            }
          </text>
        </div>
        <button className="startButton" onClick={handleStartButton}>
          Let's START building!
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}
