import React, { Component } from "react";
import "./summaryContent.css";
import buildadreamLogo from "../../resources/buildadream.svg";
import pillowLogo from "../../resources/pillow.png";
import neckPainLogoWhite from "../../resources/physicalPain/neck_pain_white.svg";
import backPainLogoWhite from "../../resources/physicalPain/back_pain_white.svg";
import shoulderPainLogoWhite from "../../resources/physicalPain/shoulder_pain_white.svg";
import stomachLogoWhite from "../../resources/position/stomach_white.svg";
import backLogoWhite from "../../resources/position/back_white.svg";
import sideLogoWhite from "../../resources/position/side_white.svg";
import sweatingLogoWhite from "../../resources/allerges/sweating_white.svg";
import sensitiveLogoWhite from "../../resources/allerges/sensitive_white.svg";

export default class SummaryContent extends Component {
  render() {
    const {posture, allergies, movePrevious, onFinish} = this.props;

    return (
      <div>
        <div className="header">
          <img src={buildadreamLogo} alt="buildadreamLogo"></img>
          <p>
            Your Pillow
          </p>
        </div>

        <div className="suggestionItem">
          <div className="pillowImage">
            <div className="imageRectangle"></div>
            <img src={pillowLogo} alt="pillow" />
          </div>

          <div className="pillowInformation">
            <p className="pillowTitle">Based on your answers, 
            we've built your dream pillow.</p>         
            <div className="pillowStats">
              <div className="pillowFilling">
                <p className="titleText">Your dream filling</p>

                <p className="statText"> 
                  {posture === "stomach" && "Soft-filled Polyester"}
                  {posture === "back" && "Memo-fill filling "}
                  {posture === "side" && "Memo-fill filling "}
                </p>

              </div>
              <div className="pillowVerticalLine" />
              <div className="pillowCover">
                <p className="titleText">Your dream cover</p>
                <p className="statText"> 
                  {allergies.medical ? "Medical, washable on 95°C " : 
                  allergies.skin ? "Tencel®" : 
                  (allergies.dust || allergies.microbe) ? "Microfiber with BI-OME® treatment" :
                  (allergies.none || allergies.sweat) ? "Bamboo" : "None"}
                </p>
              </div>
            </div>
            <div className="pillowIconList">
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={shoulderPainLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={sideLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={stomachLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={backPainLogoWhite} alt="pillow" />
              </div>
            </div>
          </div>
        </div>

        <p className="morePillows">More pillows for you</p>

        <div className="suggestionItem">
          <div className="pillowImage">
            <div className="imageRectangle"></div>
            <img src={pillowLogo} alt="pillow" />
          </div>
          <div className="pillowInformation">
            <p className="pillowTitle">This pillow could also work well 
            for your needs </p>         
            <div className="pillowStats">
              <div className="pillowFilling">
                <p className="titleText">Your filling</p>
                <p className="statText">Soft-fill</p>
              </div>
              <div className="pillowVerticalLine" />
              <div className="pillowCover">
                <p className="titleText">Your cover</p>
                <p className="statText">Microfiber with BI-OME treatment</p>
              </div>
            </div>
            <div className="pillowIconList">
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={sweatingLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={sensitiveLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangleDark"></div>
                <img src={stomachLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={backPainLogoWhite} alt="pillow" />
              </div>
            </div>
          </div>
        </div>
        <div className="suggestionItem">
          <div className="pillowImage">
            <div className="imageRectangle"></div>
            <img src={pillowLogo} alt="pillow" />
          </div>
          <div className="pillowInformation">
            <p className="pillowTitle">This pillow could also work well 
            for your needs </p>         
            <div className="pillowStats">
              <div className="pillowFilling">
                <p className="titleText">Your filling</p>
                <p className="statText">Memo-fill filling</p>
              </div>
              <div className="pillowVerticalLine" />
              <div className="pillowCover">
                <p className="titleText">Your cover</p>
                <p className="statText">Tencel®</p>
              </div>
            </div>
            <div className="pillowIconList">
              <div className="pillowIcon">
                <div className="iconRectangleDark"></div>
                <img src={sweatingLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={backPainLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangleDark"></div>
                <img src={sensitiveLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={stomachLogoWhite} alt="pillow" />
              </div>
            </div>
          </div>
        </div>
        <div className="suggestionItem">
          <div className="pillowImage">
            <div className="imageRectangle"></div>
            <img src={pillowLogo} alt="pillow" />
          </div>
          <div className="pillowInformation">
            <p className="pillowTitle">This pillow could also work well 
            for your needs </p>         
            <div className="pillowStats">
              <div className="pillowFilling">
                <p className="titleText">Your filling</p>
                <p className="statText">70% down / 30% feathers</p>
              </div>
              <div className="pillowVerticalLine" />
              <div className="pillowCover">
                <p className="titleText">Your cover</p>
                <p className="statText">Bamboo</p>
              </div>
            </div>
            <div className="pillowIconList">
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={sensitiveLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={stomachLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangle"></div>
                <img src={sweatingLogoWhite} alt="pillow" />
              </div>
              <div className="pillowIcon">
                <div className="iconRectangleDark"></div>
                <img src={backPainLogoWhite} alt="pillow" />
              </div>
            </div>
          </div>
        </div>
        <button className="backPostureButton" onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton" onClick={onFinish}>
          Finish
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}
