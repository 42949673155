import React from "react";
import buildadreamLogo from "../../resources/buildadream.svg";
import ScaleQuestion from "../smart-components/ScaleQuestion";
import "./coverContent.css";

class CoverContent extends React.Component {
  render() {
    const {
      contents,
      handleQuestionUpdate,
      updateZip,
      movePrevious,
      moveNext,
    } = this.props;

    return (
      <div>
        <div className="header">
          <p>Cover</p>
        </div>

        <div className="coverIntroText">
          <p className="textFirstRow">
            Rate how important each of the following criteria is regarding the
            material of the cover of your pillow!
          </p>
          <p className="textSecondRow">
            1 - not important at all, 4 - very important.
          </p>
        </div>

        <ScaleQuestion
          text={"I want to be able to wash the cover often (weekly-monthly)"}
          selectedButton={contents.cover.question4}
          category={"cover"}
          questionName={"question4"}
          toggle={true}
          toggleState={contents.zip}
          setToggle={updateZip}
          handleButtonPress={handleQuestionUpdate}
          toggleText={"without zipper"}
          altToggleText={"with zipper"}
        />

        {(contents.allergies.skin || contents.allergies.none) && (
          <ScaleQuestion
            text={"I want the cover to be made from a sustainable material"}
            selectedButton={contents.cover.question1}
            category={"cover"}
            questionName={"question1"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.allergies.skin || contents.allergies.sweat) && (
          <ScaleQuestion
            text={"I want the cover to create an optimal sleeping climate"}
            selectedButton={contents.cover.question2}
            category={"cover"}
            questionName={"question2"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.allergies.skin || contents.allergies.microbe) && (
          <ScaleQuestion
            text={"I want the cover to have antimicrobial properties"}
            selectedButton={contents.cover.question3}
            category={"cover"}
            questionName={"question3"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.allergies.skin || contents.allergies.sweat) && (
          <ScaleQuestion
            text={
              "I want the cover to be made from a soft, natural, plant based material"
            }
            selectedButton={contents.cover.question5}
            category={"cover"}
            questionName={"question5"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.allergies.skin ||
          contents.allergies.microbe ||
          contents.allergies.dust ||
          contents.allergies.medical) && (
          <ScaleQuestion
            text={
              "I want to be able to disinfect the cover by washing it at a very high temperature"
            }
            selectedButton={contents.cover.question6}
            category={"cover"}
            questionName={"question6"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        {(contents.allergies.microbe ||
          contents.allergies.dust ||
          contents.allergies.sweat) && (
          <ScaleQuestion
            text={"I want the cover to have anti-mite properties"}
            selectedButton={contents.cover.question7}
            category={"cover"}
            questionName={"question7"}
            toggle={false}
            handleButtonPress={handleQuestionUpdate}
          />
        )}

        <button className="backPostureButton" onClick={movePrevious}>
          <div className="backButtonArrow"></div>
        </button>
        <button className="nextPostureButton" onClick={moveNext}>
          Next
          <div className="startButtonArrow"></div>
        </button>
      </div>
    );
  }
}

export default CoverContent;
